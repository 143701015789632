import React, { Component } from 'react';
import Project from './Project.js'
import adreamwithin from '../images/adreamwithin.png'
import birdblotter_illustration from '../images/birdblotter_illustration.jpg'
import blackhole_illustration from '../images/blackhole_illustration.jpg'
// import blackjack_screenshot from '../images/blackjack_screenshot.png'
import blockclock_illustration from '../images/blockclock_illustration.png'
// import brickbreaker from '../images/brickbreaker.png'
import constructum_illustration from '../images/constructum_illustration.jpg'
import duality_illustration from '../images/duality_illustration.jpeg'
import fcollective_illustration from '../images/fcollective_illustration.jpeg'
// import image_placeholder from '../images/image_placeholder.jpg'
// import losertictactoe_screenshot from '../images/losertictactoe_screenshot.png'
import loveBirds_illustration from '../images/loveBirds_illustration.jpeg'
import multivaria_illustration from '../images/multivaria_illustration.jpg'
import lux_illustration from '../images/lux_illustration.png'
// import onedayonepun_screenshot from '../images/1day1pun_screenshot.png'
// import simmering_screenshot from '../images/simmering_screenshot.png'
import thunderbird from '../images/Thunderbird.png'


import './components.css'



class Portfolio extends Component {

    render() {

        return (
            <div className="portfolio text">
                <h2 style={{color: '#3d484c'}}>Some stuff I built</h2>

                <Project 
                    name="Love Birds - 2023"
                    image={loveBirds_illustration} 
                    description="A blockchain love story: a collection of 2 on chain love birds NFTs looking for each other. They were sold at auction where the owner of the first NFT couldn't bid on the second. Will they find each other?"
                    technology={[ "On chain metadata and SVG", "post-transfer hooks", "Solidity", "Foundry", "React", "Web3.js"]}
                    artist={{name:'Smokestacks', twitter:'https://twitter.com/smokestacks'}} 
                    links={[{name: 'Website', link: 'https://love-birds.xyz/'},
                            {name: 'OS', link: 'https://opensea.io/collection/love-birds-6'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/loveBirds'},]}/>


                <Project 
                    name="Constructum - 2023"
                    image={constructum_illustration} 
                    description="An interactive NFT where the user builds it. 125 possible combinations with supply much smaller..."
                    technology={[ "Build-yout-own NFT", "NFT reveal", "IPFS", "Truffle", "React", "Web3.js"]}
                    artist={{name:'Simply Anders', twitter:'https://twitter.com/liquidmarbles'}} 
                    links={[{name: 'Website', link: 'https://constructum.art/'},
                            {name: 'OS', link: 'https://opensea.io/collection/constructum'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/constructum'},]}/>

                <Project 
                    name="MULTIVARIA - 2022"
                    image={multivaria_illustration} 
                    description="An NFT where two tokens are obtained upon mint. The two tokens are required to perform a 'shift' and get one random NFT amongst 11 with different rarities for each"
                    technology={[ "Merge & burn mechanism", "Draw without replacement onchain randmoness", "Truffle", "React", "Web3.js"]}
                    artist={{name:'Mike Neff', twitter:'https://twitter.com/mkeneff'}} 
                    links={[{name: 'Website', link: 'https://multivaria.xyz/'},
                            {name: 'OS', link: 'https://opensea.io/collection/multivaria'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/Multivaria'},]}/>

                                
                <Project 
                    name="Anastasis - 2022"
                    image={fcollective_illustration} 
                    description="3 collections for a collective of 14 artists with custom release mechanisms, including a custom on chain auction contract and an onchain automated revenue split contract handling both ETH and ERC20 tokens"
                    technology={[ "Fund splitting contract", "Custom auction contract", "Truffle", "React", "Web3.js"]}
                    artist={{name:'F-collective', twitter:'https://twitter.com/F__Collective'}} 
                    links={[{name: 'Website', link: 'https://f1dropanastasis.art/'},
                            {name: 'Act 1 (OS)', link: 'https://opensea.io/collection/f-1-anastasis-act1'},
                            {name: 'Act 2 (OS)', link: 'https://opensea.io/collection/f-1-anastasis-act2-v2'},
                            {name: 'Act 3 (OS)', link: 'https://opensea.io/collection/f-1-anastasis-act3'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/Anastasis'},]}/>
                
                <Project 
                    name="BlockClocks - 2022"
                    image={blockclock_illustration} 
                    description="A 100 % on-chain dynamic and interactive NFT which illustrates how the blockchain comprehends time"
                    technology={[ "Onchain metadata & randomness", "Upgradable Pattern", "Truffle", "React", "Web3.js"]}
                    artist={{name:'Smartcontrart', twitter:'https://twitter.com/SmartContrart'}} 
                    links={[{name: 'Website', link: 'https://block-clock.xyz/'},
                            {name: 'Opensea', link: 'https://opensea.io/collection/block-clock'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/BlockClock'},]}/>
                
                <Project 
                    name="Lux - 2022"
                    image={lux_illustration} 
                    description="A collection of 4 NFTs with different distribution mechanisms and rariies"
                    technology={["Burn/Own to mint contracts", "Truffle", "React", "Web3.js"]}
                    artist={{name:'Daniel Koeth', twitter:'https://twitter.com/danielkoeth'}} 
                    links={[{name: 'Website', link: 'https://dklux.xyz/'},
                            {name: 'Opensea', link: 'https://opensea.io/collection/luxbydk'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/Lux'},]}/>

                <Project 
                    name="Birdblotter - 2022"
                    image={birdblotter_illustration} 
                    description="A token gated e-commerce shop where Moonbird holders can mint a derivative of their bird to redeem a physical blotter"
                    technology={[ "Automated creation of 10k pfp jpgs", "Custom encrypted OMS", "Custom encrypted database", "Truffle", "React", "Web3.js"]}
                    artist={{name:'Joe Pinecone', twitter:'https://twitter.com/nbkmdcdoteth'}} 
                    links={[{name: 'Website', link: 'https://birdblotter.xyz/'},
                            {name: 'Opensea', link: 'https://opensea.io/collection/birdblotter'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/Birdblotter'},]}/>

                <Project 
                    name="Blackhole - 2022"
                    image={blackhole_illustration} 
                    description="An experimental art project leveraging a utility ERC20 token to shape your NFT"
                    technology={["Storage oor ERC20 in ERC721", "Edition and 1/1 collection", "ERC721", "Truffle", "Web3.js", "React"]}
                    artist={{name:'Grecu', twitter:'https://twitter.com/the_grecu'}}
                    links={[{name: 'Website', link: 'https://thegreatvoid.art/'},
                            {name: 'Opensea', link: 'https://opensea.io/collection/blackholebygrecu'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/blackhole'},]}/>

                <Project 
                    name="Duality - 2022"
                    image={duality_illustration} 
                    description="An interchangeable sets of audio-visual choices to create your own NFT"
                    technology={["ERC1155", "Signature off-chain allow-list", "Token exchange mechanism", "Truffle", "Web3.js", "React"]}
                    artist={{name:'Noobcoin', twitter:'https://twitter.com/noobcoin_'}}
                    links={[{name: 'Website', link: 'https://www.duality-nft.art/'},
                            {name: 'Opensea', link: 'https://opensea.io/collection/noobcoin-duality'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/Duality'},]}/>

                <Project 
                    name="A dream within a dream - 2022"
                    image={adreamwithin} 
                    description="An NFT collection where each NFT changes visual upon transfer and can never go back"
                    technology={[ "Post-transfer hook", "ERC721", "ERC20-based minting", "Truffle", "Web3.js", "React"]}
                    artist={{name:'Grecu', twitter:'https://twitter.com/the_grecu'}}
                    links={[{name: 'Website', link: 'https://arweave.net/BaEsH1FGovXin5pavwSXL5y209uqkyAV6FguYIiwjUA'},
                            {name: 'Opensea', link: 'https://opensea.io/collection/adreamwithinadream'},
                            {name: 'Github', link: 'https://github.com/smartcontrart/adreamwithinadream'},]}/>

                <Project 
                    name="Thunderbird - 2021"
                    image={thunderbird} 
                    description="A proof of concept of a video streaming service powered by the lightning network where you pay each second where data is loaded to your computer"
                    technology={[ "Bitcoin", "Lightning Network", "React", "ExpressJS"]}
                    artist={{name:'N.A.', twitter:''}} 
                    links={[{name: 'Github', link: 'https://github.com/smartcontrart/Thunderbird'}]}/>
{/* 
                <Project 
                    name="Python Brick breaker - 2021"
                    image={brickbreaker} 
                    description="Brick Breaker, an all time classic. Good first challenge for a test of the Neat algorithm implementation in Python. Also coded the game with Pygame"
                    technology={[ "Python", "Pygame", "Neat-python"]}
                    links={[{name: 'Demo', link: 'https://replit.com/@Bechev/Python-Brick-Breaker-with-AI'}, {name: 'Github', link: 'https://github.com/smartcontrart/Python_brickBreaker_AI'}]}/>

                <Project 
                    name="One day, one pun - 2020"
                    image={onedayonepun_screenshot} 
                    description="Too lame to be dad jokes, too important not to exist. Your daily pun provided through Twitter"
                    technology={[ "Twitter API", "Ruby on Rails", "Heroku"]}
                    links={[{name: 'Twitter', link: 'https://twitter.com/1day1pun'}, {name: 'Github', link: 'https://github.com/smartcontrart/OneDayOnePun'}]}/>
                
                <Project 
                    name="Meal Planning WebApp - 2019"
                    image={simmering_screenshot}
                    description="A website that changes the way you do your groceries by making it easy to eat seasonal and local food while reducing waste (You can use the following credentials in the demo website: user1@email.com password1)."
                    technology={[ "React-Redux", "Ruby on Rails", "SQLite", "Selenium scripts", "RESTful API", "CRUD"]}
                    links={[{name: 'Website', link: 'https://simmering.herokuapp.com/'}, {name: 'Github', link: 'https://github.com/smartcontrart/recipeApp'}]}/>

                <Project 
                    name="Online Black Jack - 2018"
                    image={blackjack_screenshot} 
                    description="When gambling is illegal, you can always play Black Jack here. And you won't lose money. Won't make any either though..."
                    technology={[ "React", "Ruby on Rails", "PostgreSQL"]}
                    links={[{name: 'Website', link: 'https://lightnino.herokuapp.com/'}, {name: 'Github', link: 'https://github.com/smartcontrart/blackjack'}]}/> */}

            </div>  
        )
    }
}

export default Portfolio;