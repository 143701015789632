import React, { Component } from 'react';
import solidity_illustration from '../images/solidity_illustration.png';
import truffle_illustration from '../images/truffle_illustration.png';
import hardhat_illustration from '../images/hardhat_illustration.png';
import foundry_illustration from '../images/foundry_illustration.png';
import ganache_illustration from '../images/ganache_illustration.png';
import javascript_illustration from '../images/javascript_illustration.png';
import nodeJS_illustration from '../images/nodejs_illustration.png';
import ethers_illustration from '../images/ethers_illustration.png';
import web3_illustration from '../images/web3_illustration.png';
import react_illustration from '../images/react_illustration.png';
import python_illustration from '../images/python_illustration.png';
import openzeppelin_illustration from '../images/openzeppelin_illustration.png';
import chai_illustration from '../images/chai_illustration.png';
import ruby_illustration from '../images/ruby_illustration.png';
import rest_illustration from '../images/rest_illustration.webp';
import HTML5_illustration from '../images/html5_illustration.png';
import './components.css'

class Skills extends Component {

  skills = [    
    {name: "Solidity", illustration: solidity_illustration},
    {name: "Truffle", illustration: truffle_illustration},
    {name: "Hardhat", illustration: hardhat_illustration},
    {name: "Foundry", illustration: foundry_illustration},
    {name: "Ganache", illustration: ganache_illustration},
    {name: "Javascript", illustration: javascript_illustration},
    {name: "NodeJS", illustration: nodeJS_illustration},
    {name: "Ethers.js", illustration: ethers_illustration},
    {name: "Web3.js", illustration: web3_illustration},
    {name: "React", illustration: react_illustration},
    {name: "Python", illustration: python_illustration},
    {name: "OpenZepplin contracts", illustration: openzeppelin_illustration},
    {name: "Mocha and ChaiJS", illustration: chai_illustration},
    {name: "Ruby on Rails", illustration: ruby_illustration},
    {name: "REST APIs", illustration: rest_illustration},
    {name: "HTML5/CSS", illustration: HTML5_illustration},
  ]

  renderSkills(){
    return(
      this.skills.map((skill, index) =>{
        return(
          <div className='skill'>
            <img
              className="skill_illustration"
              src={skill.illustration}
              alt=''></img>
              <div>{skill.name}</div>
          </div>
        )
      })
    )
  }

    render() {

        return (
            <div className="about">
                <h2 style={{color: '#3d484c'}}>Skills</h2>
                  <div className='skills_tagline'>
                    Full stack dev, my weapons of choice are:
                  </div>
                  <div className='skills'>
                    {this.renderSkills()}
                  </div>
            </div>
        )
    }

}

export default Skills;


