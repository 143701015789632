import React, { Component } from 'react';
import './components.css';


class Project extends Component {

    renderTechnology(){
        return(
            this.props.technology.map(technology => {
                return(
                    <li>
                        {technology}
                    </li>
                )
            })
        )
    }

    renderLinks(){
        return(
            this.props.links.map(link => {
                return(
                    <a href={link.link} 
                    className='link'
                    style={{color: '#3d484c', textDecorationLine: 'underline'}}
                    target="_blank"
                    rel="noopener noreferrer">
                        {link.name}
                    </a>
                )
            })
        )
    }

    renderArtist(){
        if(this.props.artist.name !== "N.A."){
            return(
                <>
                    Artist: <a href={this.props.artist.twitter} 
                    style={{color: '#3d484c', textDecorationLine: 'underline'}}
                    target="_blank"
                    rel="noopener noreferrer">{this.props.artist.name}</a>
                </>
            )
        }
    }


    render() {
        
        return (
            <div className="project">
                
                <div className="project_header">
                    <h3 className="project_name">
                        {this.props.name}
                    </h3>
                </div>

                <div className="project_body">
                    <div>
                        <img
                        className="project_image"
                        width='250px'
                        height='250px'
                        src={this.props.image}
                        alt=''></img>
                        <div className='artist'>{this.renderArtist()}</div>
                    </div>

                    <div className="project_card">
                        <div className="project_description">
                            {this.props.description}
                        </div>

                        <div className="project_technology">
                            <ul>
                                {this.renderTechnology()}
                            </ul>
                        </div>

                        <div className="project_links">
                            {this.renderLinks()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Project;