import React, { Component } from 'react';
import './components.css'

class About extends Component {

    render() {

        return (
            <div className="about">
                <h2 style={{color: '#3d484c'}}>About</h2>
                <div class='text'>
                    <p>
                        <span>
                            I'm passionate about Crypto and the blockchain technology. I've been active in the space for the past five years and full time for one.
                            <br/>
                            I do mostly NFTs with 20+ full end-to-end projects developped (front-end, smart contracts, deployment and everything in between for a successful project), but I also joined the Airswap DAO which builds DeFi protocols.
                            <br/>
                            <br/>
                            Feel free to reach out: <a href="https://twitter.com/smartcontrart" style={{textDecoration: 'underline'}} target='_blank' rel="noopener noreferrer">Twitter</a> ...
                            <br/>
                            <br/>
                            ...or scroll down to learn more about what I built.
                        </span>
                    </p>
                </div>
            </div>
        )
    }

}

export default About;