import React from 'react';
import About from './components/About.js'
import Contact from './components/Contact.js'
import Portfolio from './components/Portfolio.js'
import ScrollArrow from './components/ScrollArrow'
import Skills from './components/Skills.js';
import './App.css';

function App() {

  return (
    <div className="App">
      <div className="background">
        <h1 style={{color: '#3d484c'}}>Smartcontrart</h1>
        <div className="description">
          I love crypto and building.
        </div>
        <Contact/>
        <About/>
        <br/>
        <Portfolio/>
        <Skills/>
        <div className='reach_out'>
          <a href='https://twitter.com/smartcontrart'
          style={{color: 'black', textDecoration: 'underline'}}>
            Want to collab? Please reach out!
          </a>
        </div>
        <ScrollArrow/>
        <Contact/>
      </div>
    </div>
  );
}

export default App;
